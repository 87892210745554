import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GppGoodIcon from "@mui/icons-material/GppGood";
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
// import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
// eslint-disable-next-line import/no-cycle
import { useDispatch } from "react-redux";
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers';
import { useAppSelector } from "../../../app/store";
import reducerPath from "../../../app/reducerPath";
import {
  CARGO_PLACEMENT_CRITERIA,
  LogisticTasksEnum,
  logisticTasksResourceActions,
  logisticTasksResourceInitialState,
  OPTIMIZATION_CRITERIA,
  SPEED_PROFILES
} from "../../../entities/logistic/logisticTasks/redux/logisticTasks.slice";
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import useDebounce from '../../../shared/hooks/useDebounce';
import FormDialog from '../../../shared/ui/FormDialog/FormDialog';

export const ORDER_STATUS = {
  OK: 'OK',
  OVERLOAD: 'OVERLOAD',
  WORK_SHIFT_TIME_NOT_EXIST: 'WORK_SHIFT_TIME_NOT_EXIST',
  LOADING_AFTER_WORK_SHIFT: 'LOADING_AFTER_WORK_SHIFT',
  LOADING_BEFORE_WORK_SHIFT: 'LOADING_BEFORE_WORK_SHIFT',
  DELIVERY_AFTER_WORK_SHIFT: 'DELIVERY_AFTER_WORK_SHIFT',
  DELIVERY_BEFORE_WORK_SHIFT: 'DELIVERY_BEFORE_WORK_SHIFT',
  ARRIVAL_TIME_NOT_EXIST: 'ARRIVAL_TIME_NOT_EXIST',
  LATE_ARRIVAL: 'LATE_ARRIVAL',
  EARLIER_ARRIVAL: 'EARLIER_ARRIVAL',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  IS_NOT_IN_TIME: 'IS_NOT_IN_TIME',
  SCHEDULE_VIOLATIONS: 'SCHEDULE_VIOLATIONS',
};

export const toRusError = (e) => {
  switch (e) {
    case ORDER_STATUS.IS_NOT_IN_TIME: return "Опаздывает";
    case ORDER_STATUS.INVALID_ADDRESS: return "Неверный адрес";
    case ORDER_STATUS.SCHEDULE_VIOLATIONS: return "Нарушен график";
    case ORDER_STATUS.OVERLOAD: return "Перегруз";
    case ORDER_STATUS.OK: return "Ошибок нет";
    case ORDER_STATUS.WORK_SHIFT_TIME_NOT_EXIST: return "Рабочей смены не существует";
    case ORDER_STATUS.LOADING_AFTER_WORK_SHIFT: return "Погрузка после рабочей смены";
    case ORDER_STATUS.LOADING_BEFORE_WORK_SHIFT: return "Погрузка перед рабочей сменой";
    case ORDER_STATUS.DELIVERY_AFTER_WORK_SHIFT: return "Доставка после рабочей смены";
    case ORDER_STATUS.DELIVERY_BEFORE_WORK_SHIFT: return "Доставка перед рабочей сменой";
    case ORDER_STATUS.ARRIVAL_TIME_NOT_EXIST: return "Время прибытия не существует";
    case ORDER_STATUS.LATE_ARRIVAL: return "Позднее прибытие";
    case ORDER_STATUS.EARLIER_ARRIVAL: return "Раннее прибытие";

    default: return "Ошибка не найдена";
  }
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function CustomizedMenus({ row, onDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(null);
  };
  const handleDelete = (event) => {
    handleClose(event);
    onDelete(row);
  };

  return (
    <div>
      <IconButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Редактировать
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem sx={{ color: "red" }} onClick={handleDelete} disableRipple>
          <DeleteIcon style={{ color: "red" }} />
          Удалить
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

CustomizedMenus.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    registrationNumber: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Номер/Название',
    width: "116px"
  },
  {
    id: 'color',
    numeric: false,
    disablePadding: true,
    label: '',
    width: "30px"
  },
  {
    id: 'startWork',
    numeric: true,
    disablePadding: false,
    label: 'Начало',
    width: "186px"
  },
  {
    id: 'finishWork',
    numeric: true,
    disablePadding: false,
    label: 'Завершение',
    width: "186px"
  },
  {
    id: 'countOrders',
    numeric: true,
    disablePadding: false,
    label: 'Кол-во заказов',
    width: "142px"
  },
  {
    id: 'maxWeightPercent',
    numeric: true,
    disablePadding: false,
    label: 'Макс. вес, %',
    width: "142px"
  },
  {
    id: 'liftingCapacity',
    numeric: true,
    disablePadding: false,
    label: 'Грузоподъёмность, кг(мест)',
    width: "100px"
  },
  {
    id: 'km',
    numeric: true,
    disablePadding: false,
    label: 'Км',
    width: "110px"
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
    width: "116px"
  },
  {
    id: 'func',
    numeric: false,
    disablePadding: false,
    label: '',
    width: "30px"
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick, numSelected, rowCount
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ width: headCell.width || null }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// eslint-disable-next-line react/prop-types
function EnhancedTableToolbar({ allErrorsLength }) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Box
        sx={{
          display: "flex", alignItems: "center", flex: "1", gap: "10px"
        }}
      >
        <Typography
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Транспортные средства
        </Typography>
        <Box
          sx={{
            display: "flex", alignItems: "center", flex: "1"
          }}
        >
          {allErrorsLength
            ? (
              <Box sx={{
                display: "flex", alignItems: "center", gap: "5px", width: "max-content"
              }}
              >
                <ReportProblemIcon sx={{ color: "red" }} />
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{ color: "red" }}
                >
                  {allErrorsLength}
                  {' '}
                  {allErrorsLength === 0
                    ? "Предупреждений"
                    : allErrorsLength <= 4
                      ? "Предупреждения"
                      : allErrorsLength >= 5
                        ? "Предупреждений"
                        : "Предупреждений" }
                </Typography>
              </Box>
            )
            : null}
        </Box>

      </Box>

    </Toolbar>
  );
}

export default function DeliveryMainWidget() {
  const logisticTask = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);
  const {
    selectedVehicles,
    rePlanDate,
    data,
  } = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);

  const {
    vehicleTypeFromStore,
    optimizationCriteriaFromStore,
    cargoPlacementCriteriaFromStore,
  } = React.useMemo(() => ({
    vehicleTypeFromStore: data.source?.vehicleType,
    optimizationCriteriaFromStore: data.source?.optimizationCriteria,
    cargoPlacementCriteriaFromStore: data.source?.cargoPlacementCriteria,
  }), [data?.source]);

  const vehicles = React.useMemo(() => (
    logisticTask?.data?.sourceWithSolution?.vehicleDetailsList || []
  ), [logisticTask?.data?.sourceWithSolution?.vehicleDetailsList]);
  const dispatch = useDispatch();

  const allErrorsLength = React.useMemo(() => vehicles?.reduce((prev, veh) => {
    let sum = prev;
    const len = Number(veh?.statuses?.length);
    if (!Number.isNaN(len)) {
      sum += len;
    }
    return sum;
  }, 0), [vehicles]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  // const [selected, setSelected] = React.useState(
  //   dataLogisticTaskId?.solution?.routes?.map((_, i) => i) || []
  // );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [vehicleToDelete, setVehicleToDelete] = React.useState(null);
  const [deleteVehicleOrders, setDeleteVehicleOrders] = React.useState(false);

  const setDeleteVehicleOrdersCheck = () => {
    setDeleteVehicleOrders((prev) => !prev);
  };

  const deleteVehicle = () => {
    dispatch(logisticTasksResourceActions.removeVehicleFromTask({
      vehicleToDelete,
      deleteVehicleOrders,
    }));
    setDeleteVehicleOrders(false);
    setVehicleToDelete(null);
  };

  const handleSelectAllClick = (event) => {
    let newSelected = [];
    if (event.target.checked) {
      newSelected = vehicles.map((n) => n.id);
    }
    dispatch(logisticTasksResourceActions.setData({
      [LogisticTasksEnum.selectedVehicles]: newSelected,
      [LogisticTasksEnum.selectedOrders]: [],
    }));
  };

  const handleClick = (event, id) => {
    const selected = selectedVehicles;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    dispatch(logisticTasksResourceActions.setData({
      [LogisticTasksEnum.selectedVehicles]: newSelected,
      [LogisticTasksEnum.selectedOrders]: [],
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selectedVehicles.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vehicles.length) : 0;

  // Создаем промежуточный стейт внутри компонента, чтобы не лагало при вводе текста в поле
  const [fastTaskName, setFastTaskName] = React.useState('');
  const onSetTaskNameDebounced = useDebounce((value) => {
    dispatch(logisticTasksResourceActions.setTaskName(value));
  }, 500);
  const onTaskNameChanged = (value) => {
    setFastTaskName(value);
    onSetTaskNameDebounced(value);
  };
  // Из редакса поднимаем актуальное значение, чтобы поле было всегда привязано к стору
  React.useEffect(() => {
    setFastTaskName(logisticTask?.data?.name || '');
  }, [logisticTask?.data?.name]);

  React.useEffect(() => (
    // При unmount-е обнуляем редакс, чтобы не аффектило следующие задачи
    () => dispatch(logisticTasksResourceActions.setData(logisticTasksResourceInitialState))
  ), []);

  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
  const [vehicleType, setVehicleType] = React.useState(vehicleTypeFromStore);
  const [
    optimizationCriteria,
    setOptimizationCriteria,
  ] = React.useState(optimizationCriteriaFromStore);
  const [
    cargoPlacementCriteria,
    setCargoPlacementCriteria,
  ] = React.useState(cargoPlacementCriteriaFromStore);

  const togleSettings = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  React.useEffect(() => {
    setVehicleType(vehicleTypeFromStore);
    setOptimizationCriteria(optimizationCriteriaFromStore);
    setCargoPlacementCriteria(cargoPlacementCriteriaFromStore);
  }, [vehicleTypeFromStore, optimizationCriteriaFromStore, cargoPlacementCriteriaFromStore]);

  const saveSettings = () => {
    dispatch(logisticTasksResourceActions.setTaskOptions({
      vehicleType, optimizationCriteria, cargoPlacementCriteria
    }));
    setIsSettingsOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, p: 2 }} elevation={1}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            display="flex"
            gap={2}
            flexDirection="column"
          >
            <TextField
              sx={{ flexGrow: 1 }}
              onChange={(event) => onTaskNameChanged(event.target.value)}
              id="lat"
              label="Название"
              value={fastTaskName || ''}
              type="text"
              variant="outlined"
            />
            <Box>
              <Stack gap="20px" direction="horizontal" sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <DateTimePicker
                  label="Начало маршрута, не ранее"
                  onChange={(e) => {
                    const isValidDate = e instanceof Date && !Number.isNaN(e.getTime());
                    dispatch(logisticTasksResourceActions.setRePlanDate((
                      isValidDate ? e.toISOString() : null
                    )));
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ minWidth: '260px' }}
                      variant="standard"
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: 'дд.мм.гггг чч:мм' }}
                    />
                  )}
                  components={{
                    ActionBar: ActionBarDateTimePicker,
                  }}
                  value={rePlanDate}
                />
                <IconButton
                  aria-haspopup="true"
                  onClick={togleSettings}
                  color={isSettingsOpen ? "primary" : "default"}
                  sx={{ top: '2px' }}
                >
                  <SettingsIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper sx={{ width: '100%', mb: 2 }} elevation={1}>
        <EnhancedTableToolbar
          numSelected={selectedVehicles.length}
          allErrorsLength={allErrorsLength}
        />
        <TableContainer>
          <Table
            sx={{ width: "100%" }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              numSelected={selectedVehicles.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={vehicles.length}
            />
            <TableBody>
              {(rowsPerPage > 0
                ? vehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : vehicles
              )?.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="right">
                      <Box sx={{
                        background: row.color,
                        width: "15px",
                        height: "15px"
                      }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      { row.startWork}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                        flexDirection: "row-reverse"
                      }}
                      >
                        { row.finishWork }
                        {row?.statuses?.includes(ORDER_STATUS.IS_NOT_IN_TIME) ? <WarningIcon sx={{ color: "red" }} /> : null}
                      </Box>
                    </TableCell>
                    <TableCell align="right">{row.countOrders}</TableCell>
                    <TableCell align="right">{row.maxWeightPercent}</TableCell>
                    <TableCell align="right">{row.liftingCapacity}({row.availableCargoCount})</TableCell>
                    <TableCell align="right">{row.km}</TableCell>
                    <Tooltip
                      arrow
                      placement="left-end"
                      title={row?.statuses?.length ? row?.statuses?.includes("OK") ? null : (
                        <div>
                          {row?.statuses?.map((err, i) => i < 15 && (
                          // eslint-disable-next-line react/no-array-index-key
                          <React.Fragment key={i}>
                            <p>
                              {i + 1}
                              {' '}
                              {err !== ORDER_STATUS.OK ? "предупреждение" : "" }
                              {' '}
                              {err ? toRusError(err) : "-"}
                            </p>
                            {i === 14 ? (
                              <p>
                                ...
                              </p>
                            ) : ""}
                          </React.Fragment>
                          ))}
                        </div>
                      ) : null}
                    >
                      <TableCell align="left" sx={{ color: "red" }}>
                        {row?.statuses?.length ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: 'center',
                              gap: "5px",
                              width: '100%',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {row?.statuses?.includes(ORDER_STATUS.OK)
                              ? (
                                <GppGoodIcon sx={{ color: "green" }} />
                              )
                              : (
                                <>
                                  {row?.statuses?.length}
                                  {' '}
                                  <ReportProblemIcon sx={{ color: "red" }} />
                                </>
                              )}
                          </Box>
                        ) : null}
                      </TableCell>
                    </Tooltip>
                    <TableCell align="left"><CustomizedMenus row={row} onDelete={setVehicleToDelete} /></TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={vehicles.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormDialog
        disableTextField
        open={isSettingsOpen}
        onSave={saveSettings}
        handleClose={() => {
          setIsSettingsOpen(false);
        }}
        dialogContent={(
          <>
            <Box component="fieldset" sx={{ p: '10px 0 0 0', border: 'none' }}>
              <legend>Профиль скорости</legend>
              <FormControl>
                <ToggleButtonGroup
                  value={vehicleType}
                  exclusive
                  onChange={(evt, value) => setVehicleType(value)}
                  aria-label="Platform"
                  size="small"
                >
                  <ToggleButton value={SPEED_PROFILES.PEDESTRIAN}>
                    <Tooltip title="Пешеход">
                      <DirectionsWalkIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={SPEED_PROFILES.BIKE}>
                    <Tooltip title="Велосипедист">
                      <PedalBikeIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={SPEED_PROFILES.MOTO}>
                    <Tooltip title="Мотоциклист">
                      <TwoWheelerIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={SPEED_PROFILES.CAR}>
                    <Tooltip title="Авто">
                      <DirectionsCarIcon />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Box>
            <Box component="fieldset" sx={{ mt: 2, p: '10px 0 0 0', border: 'none' }}>
              <legend>Критерии оптимизации</legend>
              <FormControl fullWidth>
                <ToggleButtonGroup
                  value={optimizationCriteria}
                  exclusive
                  onChange={(evt, value) => setOptimizationCriteria(value)}
                  aria-label="Platform"
                  size="small"
                >
                  <ToggleButton value={OPTIMIZATION_CRITERIA.MINIMIZE_VEHICLES}>
                    Минимизация количества тс
                  </ToggleButton>
                  <ToggleButton value={OPTIMIZATION_CRITERIA.EQUAL_MILEAGES}>
                    Равномерный пробег
                  </ToggleButton>
                  <ToggleButton value={OPTIMIZATION_CRITERIA.EQUAL_ORDERS}>
                    Равномерное распределение заказов
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Box>
            <Box component="fieldset" sx={{ mt: 2, p: '10px 0 0 0', border: 'none' }}>
              <legend>Учет объема размещения грузов</legend>
              <FormControl fullWidth>
                <ToggleButtonGroup
                  value={cargoPlacementCriteria}
                  exclusive
                  onChange={(evt, value) => setCargoPlacementCriteria(value)}
                  aria-label="Platform"
                  size="small"
                >
                  <ToggleButton value={CARGO_PLACEMENT_CRITERIA.CAPACITY_IN_KG}>
                    В килограммах
                  </ToggleButton>
                  <ToggleButton value={CARGO_PLACEMENT_CRITERIA.CAPACITY_IN_UNITS}>
                    В кол-ве грузомест
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Box>
          </>
        )}
        title="Настройки"
        saveTitle="ОК"
        cancelTitle="Отмена"
      />
      <FormDialog
        disableTextField
        open={vehicleToDelete}
        onSave={deleteVehicle}
        handleClose={() => {
          setDeleteVehicleOrders(false);
          setVehicleToDelete(null);
        }}
        dialogContent={(
          <FormControlLabel
            control={(
              <Checkbox
                value={deleteVehicleOrders}
                onChange={setDeleteVehicleOrdersCheck}
              />
            )}
            label="Удалить связанные заказы"
          />
        )}
        title={(
          <>
            Вы действительно хотите удалить
            {' '}
            {vehicleToDelete?.registrationNumber}
            {' '}
            из задачи?
          </>
        )}
        saveTitle="ОК"
        cancelTitle="Отмена"
      />
    </Box>
  );
}
